<template>
  <main class="container mx-auto flex flex-col items-center py-10 px-4">
    <h1 class="text-2xl font-bold mb-10">開室通知設定</h1>
    <m-comment-panel class="mb-12"> {{ comment }} </m-comment-panel>
    <p class="w-full font-medium mb-1">通知対象曜日の選択</p>
    <div
      class="w-full px-4 pt-4 mb-12 flex flex-wrap justify-center bg-white rounded-md"
    >
      <div v-for="weekday in weekdays" :key="weekday.index">
        <div
          class="text-gray-700 font-medium text-center mx-7 mb-3 text-lg font-medium"
        >
          {{ weekday.text }}
        </div>
        <div class="flex items-center justify-center w-full mb-8">
          <label class="flex items-center cursor-pointer">
            <div class="relative">
              <input
                type="checkbox"
                class="sr-only"
                checked
                @click="changeWeekday(weekday.index)"
              />
              <div
                class="block w-14 h-8 rounded-full"
                :class="[
                  !selectedWeekdays.includes(weekday.index)
                    ? 'bg-gray-200'
                    : 'bg-green-500'
                ]"
              ></div>
              <div
                class="absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform"
                :class="[
                  !selectedWeekdays.includes(weekday.index)
                    ? 'translate-x-0'
                    : 'translate-x-full'
                ]"
              ></div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <m-button class="mb-6" @click="updateNotificationSetting">
      設定する
    </m-button>
  </main>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MButton from "@/components/form/MButton.vue";
import store from "@/store";
import { Student } from "@/entities/student";
import { updateStudent } from "@/api/student";
import { saveErrorLog } from "@/api/error";

type Weekday = {
  index: number;
  text: string;
};

@Options({
  components: {
    MCommentPanel,
    MButton
  },
  watch: {
    student() {
      this.getSelectedWeekdaysOfNotification();
    }
  }
})
export default class Notification extends Vue {
  comment =
    "開室通知設定をすると、学習室が開いた際に通知が送信されるようになります！";
  weekdays: Weekday[] = [
    {
      index: 1,
      text: "月"
    },
    {
      index: 2,
      text: "火"
    },
    {
      index: 3,
      text: "水"
    },
    {
      index: 4,
      text: "木"
    },
    {
      index: 5,
      text: "金"
    },
    {
      index: 6,
      text: "土"
    },
    {
      index: 0,
      text: "日"
    }
  ];
  selectedWeekdays: number[] = [];

  get student(): Student | null {
    return store.state.student;
  }

  changeWeekday(index: number) {
    if (this.selectedWeekdays.includes(index)) {
      this.selectedWeekdays = this.selectedWeekdays.filter(
        weekday => weekday !== index
      );
    } else {
      this.selectedWeekdays.push(index);
    }
  }

  async updateNotificationSetting() {
    if (!this.student) {
      return;
    }
    const newData = this.student.data;
    newData.sundayNotify = this.selectedWeekdays.includes(0);
    newData.mondayNotify = this.selectedWeekdays.includes(1);
    newData.tuesdayNotify = this.selectedWeekdays.includes(2);
    newData.wednesdayNotify = this.selectedWeekdays.includes(3);
    newData.thursdayNotify = this.selectedWeekdays.includes(4);
    newData.fridayNotify = this.selectedWeekdays.includes(5);
    newData.saturdayNotify = this.selectedWeekdays.includes(6);

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "更新中...");
    try {
      await updateStudent(this.student.ref, newData);
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(`予期せぬエラーが発生しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to update student"
      );
      return;
    }
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
    this.$router.push("/setting");
  }

  getSelectedWeekdaysOfNotification() {
    if (
      this.student &&
      (this.student.data.sundayNotify ||
        this.student.data.mondayNotify ||
        this.student.data.tuesdayNotify ||
        this.student.data.wednesdayNotify ||
        this.student.data.thursdayNotify ||
        this.student.data.fridayNotify ||
        this.student.data.saturdayNotify)
    ) {
      const weekdays: number[] = [];
      if (this.student.data.sundayNotify) {
        weekdays.push(0);
      }
      if (this.student.data.mondayNotify) {
        weekdays.push(1);
      }
      if (this.student.data.tuesdayNotify) {
        weekdays.push(2);
      }
      if (this.student.data.wednesdayNotify) {
        weekdays.push(3);
      }
      if (this.student.data.thursdayNotify) {
        weekdays.push(4);
      }
      if (this.student.data.fridayNotify) {
        weekdays.push(5);
      }
      if (this.student.data.saturdayNotify) {
        weekdays.push(6);
      }
      this.selectedWeekdays = weekdays;
    }
  }

  created() {
    this.getSelectedWeekdaysOfNotification();
    const fromReflection = this.$route.query.reflection;
    if (!fromReflection) {
      return;
    }
    this.comment =
      "続けて開室通知の設定をしよう！開室通知を設定すると、学習室が開いた時にお知らせが届くようになります";
  }
}
</script>
